import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

const useForexCardAnimationMobile = () => {
  const ANIMATION_TYPE = "ease-in-out";
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    let timerId = setTimeout(() => {
      const rootContainerElement = document.getElementById("root_container");
      const bmfCardMobileElement = document.getElementById("bmfCardMobile_ref");
      const forexCardMobileElement = document.getElementById(
        "forexCardMobile_ref"
      );
      const greyMobileElement = document.getElementById("greyMobile_ref");

      gsap.to(bmfCardMobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: greyMobileElement,
          start: "top top+=80",
          toggleActions: "restart none none reverse",
        },
        y: -95,
        ease: ANIMATION_TYPE,
        duration: 0.6,
      });
      gsap.to(forexCardMobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: greyMobileElement,
          start: "top top+=80",
          toggleActions: "restart none none reverse",
        },
        y: 10,
        ease: ANIMATION_TYPE,
        duration: 0.6,
      });
    }, 300);
    return () => clearTimeout(timerId);
  }, []);
};
export default useForexCardAnimationMobile;
