import { useRouter } from "next/router";
import { useState } from "react";

import * as S from "./LandingTrans.style";
import LandingButton from "../LandingButton/LandingButton";
import {
  LandingGreySec,
  LandingGreySecWrap,
} from "../../shared-styled-components";
import { Markdown } from "../markdown";
import { Pincode } from "../pincode";
import useForexCardAnimationMobile from "../../hooks/useForexCardAnimationMobile";
import { pushEvent } from "@tm/utility/analytics";
import { Image } from "../Image";

const LandingTrans = ({ forexCardEngagement, forexCardInfo, events }: any) => {
  const [load] = useState(false);
  const router = useRouter();
  useForexCardAnimationMobile();

  const { buyNowEvent, checkPincodeEvent } = events || {};

  const onClickHandler = () => {
    if (buyNowEvent) {
      pushEvent(buyNowEvent);
    }
    if (forexCardEngagement?.buyRedirectionUrl) {
      router.push(forexCardEngagement.buyRedirectionUrl);
    }
  };
  return (
    <LandingGreySec marginTop="80px" id="greyMobile_ref">
      <LandingGreySecWrap>
        <S.LandingTrans>
          <div>
            <S.LandingTransTitle>
              {forexCardEngagement?.title}
            </S.LandingTransTitle>
            <S.LandingTransInfo>
              <Markdown>{forexCardEngagement?.subtitle}</Markdown>
            </S.LandingTransInfo>
          </div>
          <S.LandingTransLeft>
            <S.LandingTransPara>
              {forexCardInfo?.title && (
                <div
                  dangerouslySetInnerHTML={{ __html: forexCardInfo?.title }}
                />
              )}
            </S.LandingTransPara>
            <Image
              id="bmfCardMobile_ref"
              className="bmfCard"
              {...forexCardInfo?.cardImgs?.[0]}
              key={0}
            />
            <Image
              id="forexCardMobile_ref"
              className="forexCard"
              {...forexCardInfo?.cardImgs?.[1]}
              key={1}
            />
            {forexCardInfo?.forexCardBenefits && (
              <S.LandingTransList>
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: forexCardInfo.forexCardBenefits[0],
                    }}
                  />
                </li>
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: forexCardInfo.forexCardBenefits[1],
                    }}
                  />
                </li>
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: forexCardInfo.forexCardBenefits[2],
                    }}
                  />
                </li>
              </S.LandingTransList>
            )}
            <S.Flex>
              <S.PoweredByText>
                {forexCardInfo?.poweredBy?.title}
              </S.PoweredByText>
              <S.LandingPoweredBy>
                <Image
                  {...forexCardInfo?.poweredBy?.logos?.[0]}
                  className="logo1"
                />
                <Image
                  {...forexCardInfo?.poweredBy?.logos?.[1]}
                  className="logo2"
                />
              </S.LandingPoweredBy>
            </S.Flex>
            <LandingButton
              margin="15px 0 0px"
              customTitle={forexCardEngagement?.buyCtaText}
              customWidth="100%"
              handleClick={onClickHandler}
            />
          </S.LandingTransLeft>
          <Pincode
            offering="INR"
            text={forexCardEngagement?.deliveryInfo}
            load={load}
            ctaText={forexCardEngagement?.verifyPincodeCtaText}
            defaultDeliveryDays={forexCardEngagement.defaultDeliveryDays}
            onClick={(pincode) => {
              if (checkPincodeEvent?.cta) {
                checkPincodeEvent.cta.pincode = pincode;
                pushEvent(checkPincodeEvent);
              }
            }}
          />
        </S.LandingTrans>
      </LandingGreySecWrap>
    </LandingGreySec>
  );
};

export default LandingTrans;
