import styled from "styled-components";

export const LandingTrans = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
  position: relative;

  & input {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    margin-right: 8px;
    padding: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  & input.disable {
    opacity: 0.3;
    pointer-events: none;
  }

  input.focus {
    outline: none;
    border: 1px solid #008cff !important;
  }

  & input.error {
    border: 1px solid red;
  }

  .bmfCard {
    position: absolute;
    top: auto;
    bottom: 119px;
    z-index: 28;
    width: 83%;
  }
  & .forexCard {
    height: 174px;
    top: auto;
    bottom: 295px;
    position: absolute;
    z-index: 26;
    width: 83%;
  }
`;

export const LandingTransLeft = styled.div`
  background: #fff;
  border-radius: 14px;
  padding: 24px 30px;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 32px;
  position: relative;
`;

export const LandingTransTitle = styled.h3`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 32px;
`;

export const CheckBtn = styled.button`
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.21);
  border-radius: 6px;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  outline: none;
  border: none;
  cursor: pointer;
  width: 102px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LandingTransFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: absolute;
  right: 30px;
  top: 60px;

  & img {
    width: 290px;
    height: 180px;
    flex-shrink: 0;
    margin-bottom: 20px;
  }
`;

export const LandingTransCity = styled.div`
  font-family: "Open Sans Light", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  position: relative;
  margin-bottom: 8px;

  &:after {
    content: "";
    position: absolute;
    background: #d9d9d9;
    border-radius: 15px;
    width: 40px;
    height: 4px;
    top: -4px;
    left: 0;
  }
  & .greenText {
    color: #22936a;
    margin-bottom: 8px;
    font-weight: 700;
  }
`;

export const LandingTransList = styled.ul`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 18px;
  gap: 10px;
  margin-bottom: 16px;
  font-family: "Open Sans Light", sans-serif;

  & li {
    position: relative;
    margin-bottom: 0.5rem;
  }

  & li:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 14px;
    left: -18px;
    top: 4px;
    background: #41454e;
    border-radius: 14px;
  }

  .bold {
    font-weight: 700;
  }
`;

export const LandingTransPara = styled.div`
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 280px;

  .greenText {
    color: #2b825e;
    font-weight: bold;
  }
`;

export const LandingTransInfo = styled.div`
  margin-bottom: 24px;
  font-family: "Open Sans Light", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #4a4a4a;

  & .blueText {
    color: #008cff;
    font-weight: 700;
    width: 704px;
  }
`;

export const PoweredByText = styled.span`
  font-family: "Open Sans Light", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-right: 16px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const LandingPoweredBy = styled.div`
  .logo2 {
    margin: 0 0 3px 16px;
  }
`;
